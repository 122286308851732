@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.active {
  box-shadow: inset .1875rem 0 0 #ABCD03;
  background-color: #ffffe6;
}

#application-info {
  padding: 20px;
}

button, th, label {
  white-space: nowrap;
}

.btn-new {
  background-color: #ABCD03;
  color: white;
}

.btn-new:hover {
  background-color: #C1D925;
}

.btn-delete {
  background-color: #FF4D4F;
}

.btn-delete:hover {
  background-color: #FF7875;
}

.btn-edit {
  background-color: #03ABCD;
}

.btn-edit:hover {
  background-color: #3AD1E1;
}

.btn-icon {
  border: 1px solid #D9D9D9;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
}

.btn-save {
  background-color: #03ABCD;
}

.btn-save:hover {
  background-color: #3AD1E1;
}

.btn-return {
  border: 1px solid #AAAAAA;
  background-color: #FFFFFF;
}

.btn-return:hover {
  color: #3AD1E1;
  border: 1px solid #3AD1E1;
  background-color: #ffffff;
}

.btn-search {
  background-color: #03ABCD;
}

.btn-search:hover {
  background-color: #3AD1E1;
}

.btn-save-and-send {
  background-color: #03ABCD;
}

.btn-save-and-send:hover {
  background-color: #3AD1E1;
}

.shadow-lg {
  box-shadow: none;
}

/* 添加表格最小宽度设置 */
@media screen and (max-width: 768px) {
  table {
    /* min-width: 1000px; */
  }
}

/* @media (prefers-color-scheme: dark) {
  main {
    background: #3c3c3c;
    color: #e0e0e0;
  }

  .bg-white,
  .bg-gray-50,
  .bg-gray-100,
  .text-gray-600,
  .text-gray-700,
  .text-gray-800 {
    background: #2c2c2c;
    color: #e0e0e0;
  }
} */